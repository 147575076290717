import React, { Component } from "react";
import $ from 'jquery';
import { MDBDataTableV5 } from 'mdbreact';
import {callUploadService} from '../services/service'

const axios = require('axios');
class Test extends Component {
  constructor(props) {
    super(props);
    this.state = {
      datatable: '',
      files: '',
      filepath: ''
    }
  }
  
  fileBrowse = (evt) => {
    if (evt.target.files[0]) {
      evt.target.nextElementSibling.textContent = evt.target.files[0].name;
      const path = require( "path" );
      
      this.setState({filepath: evt.target.value})
      this.setState({files: evt.target.files[0]})
    }
  }

  handleSubmit (e) {
    e.preventDefault();
    $("#save-template").addClass("d-none");
    $("#loading-btn").removeClass("d-none");
    
    var uploadElement = document.querySelector("#customFile").files[0];
    const formData = new FormData();
    formData.append('file', uploadElement)
    console.log(formData)
    //callUploadService(formData)
    axios.post('/upload', formData, {
      headers: {
          "Content-type": "multipart/form-data",
      },                    
    }).then(res => {
        //alert(res.data.message);
        $("#save-template").removeClass("d-none");
        $("#loading-btn").addClass("d-none");
    })
    .catch(err => {
        console.log(err);
    })

  }
  render() {
    return (
      <div>
        <div className="d-sm-flex align-items-center justify-content-between mb-4">
            <h1 className="h3 mb-0 text-gray-800">Test Templates</h1>
        </div>
        <div className="content shadow">
          <div className="row">
            <div className="col-md-7">
              
            </div>
            <div className="col-md-5">
              <div className="tab-content">
              <form onSubmit={this.handleSubmit}>
                  <div className="form-group">
                    <label htmlFor="filename">Filename</label>
                    <div className="custom-file">
                      <input type="file"
                       className="custom-file-input"
                        id="customFile"
                         onChange={this.fileBrowse}
                          accept="application/pdf,application/" 
                          name="file" required/>
                      <label className="custom-file-label" htmlFor="customFile">Choose file</label>
                    </div>
                  </div>
                  <div className="form-group row">
                    <div className="col-sm-12">
                      <button type="submit" className="btn btn-primary waves-effect mr-3 pr-3 pl-3" id="save-template" >Submit</button>
                      <button className="btn btn-primary waves-effect mr-3 pr-3 pl-3 d-none" type="button" id="loading-btn" disabled>
                        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                        Loading...
                      </button>
                    </div>
                  </div>
                  <div className="form-group">
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Test;