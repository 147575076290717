
import $ from 'jquery';
import {
  callUpdateTemplateService
} from "../../services/service";

export const saveTemplate =(stage)=> {
    
    document.querySelector("#save-template").addEventListener("click", function(e) {
        e.preventDefault();
        const docCategory = document.querySelector("#select-template").value;
        const selectedIndex = document.querySelector("#select-template").options.selectedIndex;
        const templateName = document.querySelector("#select-template").options[selectedIndex].getAttribute('data-name');
        
        
        let getShapes = "";
        getShapes = stage.find("Rect");
        let bodyDataSend = [];
        let page_templates = {};
        let getIdens = [];
        let getKeyNames = [];
        let setTitleArray = [];
        
        let arrayShapesKey = []
        
        
        getShapes.forEach((shape, i) => {
          let keypolygon = '',
              keybbox = '',
              keytext = [],
              keyindex = '',
              keymulti = '',
              valuepolygon = '',
              valuebbox = '',
              valuecutchars = '',
              valueparser = '',
              valuecut = '',
              valuepos = '',
              valuetype = '',
              valuemulti = ''
          
          
  
          if (shape.attrs.stroke === "red"){
            if (typeof shape.attrs.text !== undefined || shape.attrs.text !== ''){
              keytext.push(shape.attrs.text)
            }
            
            keypolygon =  shape.attrs.boxData.polygon
            keybbox = shape.attrs.boxData.bounding_box
            keyindex = shape.attrs.key_index
            keymulti = shape.attrs.configs.key_multi
          }
          if (shape.attrs.stroke === "lime") {
            valuepolygon = [shape.attrs.boxData.polygon]
            valuebbox = [shape.attrs.boxData.bounding_box]
            valuecutchars = shape.attrs.configs.value_cut_chars
            valueparser = shape.attrs.configs.value_parser
            valuecut = shape.attrs.configs.value_cut
            valuepos = shape.attrs.valpos
            valuetype = shape.attrs.configs.value_type
            valuemulti = shape.attrs.configs.value_multi
          }
          
          if (shape.attrs.stroke === "blue"){
            //console.log(shape)
            setTitleArray.push({
              bounding_box: shape.attrs.boxData.bounding_box,
              polygon: shape.attrs.boxData.polygon,
              words: [shape.attrs.text],
              word_index: shape.attrs.text
            })
          }else{
            getIdens.push(shape.attrs.identifier);
            if (shape.attrs.text !== "" && shape.attrs.text !== undefined){
              getKeyNames.push(shape.attrs.text);
            }
            
          }
          
          
          if (keytext.length > 0){
            localStorage.setItem('getKey', keytext[0]);
          }
          //console.log("sdfsadfsd:", localStorage.getItem("getKey"))
            if (getIdens.includes(shape.attrs.identifier)){
              arrayShapesKey.push({
                "box_name" : localStorage.getItem("getKey"),
                "box_color" : shape.attrs.stroke,
                "key_polygon": keypolygon,
                "key_bbox": keybbox,
                "key_word": [localStorage.getItem("getKey")],
                "key_name": localStorage.getItem("getKey"),
                "key_index": keyindex,
                "key_multi": keymulti,
                "value_polygon": valuepolygon,
                "value_bbox": valuebbox,
                "value_cut_chars": valuecutchars,
                "value_parser": valueparser,
                "value_cut": valuecut,
                "value_pos": valuepos,
                "value_type": valuetype,
                "value_multi": valuemulti,
                "value_format": "999999"
    
              }); 
            }  
        });
        
        
        function insertArrayAt(array, index, arrayToInsert) {
          Array.prototype.splice.apply(array, [index, 0].concat(arrayToInsert));
          return array;
        }
        
        let keyborderbox = []
        arrayShapesKey.map((arr, i)=>{
          //console.log("arrayShapesKey:", arrayShapesKey)
          if ((arr.key_polygon !== '') ){
            keyborderbox.push({
              "key_polygon" : arr.key_polygon,
              "box_name" : arr.box_name,
              "key_name" : arr.box_name,
              "key_bbox": arr.key_bbox,
              "key_index": arr.key_index,
              "key_multi": arr.key_multi,
              "key_word": arr.key_word,
              "value_bbox_": [],
              "value_polygon_": [],
            })  
          }
  
          
          
          let carIndex = keyborderbox.findIndex( filterCarObj=> 
            filterCarObj['box_name'] === arr.box_name);
            
          if ((arr.value_polygon !== '') ){
            
            insertArrayAt(keyborderbox[carIndex], 
              0,
              keyborderbox[carIndex]['value_cut'] = arr.value_cut,
              keyborderbox[carIndex]['value_cut_chars'] = arr.value_cut_chars,
              keyborderbox[carIndex]['value_format'] = "999999",
              keyborderbox[carIndex]['value_multi'] = arr.value_multi,
              keyborderbox[carIndex]['value_parser'] = arr.value_parser,
              keyborderbox[carIndex]['value_pos'] = arr.value_pos,
              keyborderbox[carIndex]['value_bbox_'].push(arr.value_bbox),
              keyborderbox[carIndex]['value_polygon_'].push(arr.value_polygon)
              ); 
            
          }
        })
  
       keyborderbox.map((listbox,k)=> {
          
          let kbbox = keyborderbox.findIndex( filterCarObj=> 
            filterCarObj['key_name'] === listbox.key_name);
            
  
          listbox.value_bbox_.map((lb,i)=>{
            insertArrayAt(keyborderbox[kbbox],
              0,
              keyborderbox[kbbox]["addbbx"] = true,
              keyborderbox[kbbox]["value_bbox_length"] = i,
              keyborderbox[kbbox]["value"+((i===0)?'':i)+"_bbox"] = lb[0]
            )
          })
  
          listbox.value_polygon_.map((lp,k)=>{
            insertArrayAt(keyborderbox[kbbox],
              0,
              keyborderbox[kbbox]["addpolygon"] = true,
              keyborderbox[kbbox]["value_polygon_length"] = k,
              keyborderbox[kbbox]["value"+((k===0)?'':k)+"_polygon"] = lp[0]
            )
          })
  
        })
        
       
  
        page_templates.words = setTitleArray[0].words;
        page_templates.bounding_box = setTitleArray[0].bounding_box;
        page_templates.polygon = setTitleArray[0].polygon;
        page_templates.word_index = '0';
        page_templates.doc_category = docCategory;
        page_templates.page_name = templateName;
        page_templates.forms = keyborderbox;
        
        
       bodyDataSend.push(page_templates);
        callUpdateTemplateService(bodyDataSend)
        .then(res => {
          if (res){
            if (res.status === 200) {
              $("#save-confirmation").modal('show');
            }
          }
        })
        
      });
  
      document.querySelector("#close-modal").addEventListener("click", function(){
        localStorage.removeItem("getKey");
        window.location.reload()
      })
}