import React from 'react';
import ReactDOM from 'react-dom';
import 'jquery/dist/jquery.min.js';
import 'bootstrap/dist/js/bootstrap.min.js';
import 'bootstrap/dist/css/bootstrap.min.css';
//import 'mdbreact/dist/css/mdb.css';
import '../src/css/template.css';
import '../src/css/index.css';

//comment these imports if wanna edit in localhost
import AdalConfig from './config/AdalConfig'
import AuthContext from './services/Auth'

// import App from './App';
// import { StyleReset } from 'atomize';
import Main from './components/Main/Main'
import * as serviceWorker from './serviceWorker';

// UNCOMMENT for localhost
//ReactDOM.render(<Main />, document.getElementById('root'));

// comment below if wanna edit in localhost
// Handle possible callbacks on id_token or access_token
AuthContext.handleWindowCallback()
// Extra callback logic, only in the actual application, not in iframes in the app
if ((window === window.parent) && window === window.top && !AuthContext.isCallback(window.location.hash)) {
  // Having both of these checks is to prevent having a token in localstorage, but no user.
  if (!AuthContext.getCachedToken(AdalConfig.clientId) || !AuthContext.getCachedUser()) {
    AuthContext.login()
    // or render something that everyone can see
    // ReactDOM.render(<PublicPartOfApp />, document.getElementById('root'))
  } else {
    AuthContext.acquireToken(AdalConfig.endpoints.api, (message, token, msg) => {
      if (token) {
        ReactDOM.render(<Main />, document.getElementById('root'))
      }
    })
  }
}



// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();