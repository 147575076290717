// src/config/AdalConfig.js
export default {
  clientId: process.env.REACT_APP_CLIENT_ID,
  //clientId: '0aa9e59f-4938-4132-803a-ddeb38cec030',
  endpoints: {
    api: process.env.REACT_APP_CLIENT_ID // Necessary for CORS requests, for more info see https://github.com/AzureAD/azure-activedirectory-library-for-js/wiki/CORS-usage
    //api: '0aa9e59f-4938-4132-803a-ddeb38cec030',
  },
  // 'tenant' is the Azure AD instance.
  tenant: process.env.REACT_APP_TENANT_ID,
  //tenant: 'cf4a9ab7-420f-4484-95a6-10d6cdadfe03',
  // 'cacheLocation' is set to 'sessionStorage' by default (see https://github.com/AzureAD/azure-activedirectory-library-for-js/wiki/Config-authentication-context#configurable-options.
  // We change it to'localStorage' because 'sessionStorage' does not work when our app is served on 'localhost' in development.
  cacheLocation: 'localStorage'
}