import React, { Component } from "react";
import {
  Route,
  NavLink,
  HashRouter
} from "react-router-dom";


import Home from "../Home";
import App from "../App";
import Maintain from "../Maintain/Maintain";
import Sidebar from "./Sidebar";
//import Maintain from "./Maintain";
import Test from "../Test";
import Header from "./Header";



class Main extends Component {
  
  render() {
    return (
        <HashRouter>
          <div id="wrapper">
            <Sidebar />
           
            <div id="content-wrapper" className="d-flex flex-column">
              <div id="content">
                <Header />
                <div className="container-fluid">
                  <Route exact path="/" component={Home}/>
                    <Route path="/Todo" component={App}/>
                    <Route path="/Maintain" component={Maintain}/>
                    <Route path="/Test" component={Test}/>
                  </div>
              </div>
              <footer className="sticky-footer bg-white">
                <div className="container my-auto">
                    <div className="copyright text-center my-auto">
                        <span>Copyright &copy; 2020</span>
                    </div>
                </div>
              </footer>
            </div>
          </div>
        </HashRouter>
    );
  }
}
 
export default Main;