import React, { Component  } from 'react';
import $ from 'jquery';
import CanvasViewer from './CanvasViewer';
import {renderBorder} from "./showBorders";
import {
    callGetTemplateService, 
    callGetKeysService, 
    callGetTemplateFileService,
    callGetPDFFileService
} from "../../services/service";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { 
    faAngleDoubleRight,
    faAngleDoubleLeft
} from '@fortawesome/free-solid-svg-icons'

class PdfReader extends Component {
    constructor(props) {
        super(props);
        this.state = {
            value: "",
            pdfName: "",
            pdfSrc: null,
            pageCategory: "",
            titleKeyWord: "",
            keyKeyWord: "",
            valueKeyWord: "",
            keys: "",
            keyNames: [],
            templateData: "",
            temData: "",
            isLoading: true,
            isClicked: false,
            pdfUrl: ''
        }
    }
    
    onClickHandler = event => {
        let pdfPageFileName = "";
        const value = event.target.innerHTML;
        //const url = event.target.options[event.target.selectedIndex].getAttribute('data-url');
        const pdfPageName = event.target.options[event.target.selectedIndex].getAttribute('data-name');
        const pdfPageUrl = event.target.options[event.target.selectedIndex].getAttribute('data-url');
        let filename = pdfPageUrl.split('/').pop();
        
        this.setState({ value });
        this.setState({ pdfName: pdfPageName });
      
        
        const getKeyNames = [];
        const dataTemplates = this.state.temData;
        const keyNamesArray = this.state.keys;
        let pCategory = "";

        let bodayData = { filename: filename };
        
        
        this.setState({pdfUrl: process.env.REACT_APP_SERVER_URL+"doc_templates/"+filename});
        //uncomment this code below if wanna edit in localhost
        //this.setState({pdfUrl: process.env.REACT_APP_SERVER_STATIC+"doc_templates/"+filename});

        dataTemplates.map(temp => {
            if (temp.page_name === pdfPageName) {
                this.setState({pageCategory: temp.page_category});
                pCategory = temp.page_category;
                temp.page_templates.map(category => {
                    this.setState({titleKeyWord: category.words})
                    if (category.forms.length > 0){
                        this.setState({keyKeyWord: category.forms[0].key_word})
                        this.setState({valueKeyWord: category.forms[0].value_pos})
                    }
                    
                })
            }
        });
        
        keyNamesArray.map(kName=> {
            if (pCategory === kName.doc_category) {
                kName.key_names.map(klist=>{
                    const keyList = klist;
                    getKeyNames.push(keyList)
                });
            }
        });

        this.setState({keyNames: getKeyNames})
        //RENDER BORDERS
        setTimeout(function () {
            pdfPageFileName = this.state.pdfName;

            if (!this.state.isLoading) {
                let templates = {};
                const pageTempData = this.state.temData;
                templates.templates = pageTempData;
                renderBorder(templates, pdfPageFileName);
            }
        }.bind(this),1000); 
    }
    
    handleChangeEvent(val) {
        return val;
    }

    handleAdvanceConfig(e) {
        if(e.target.checked){
            document.querySelector(".config-wrap").style.display = 'block';
        }else{
            document.querySelector(".config-wrap").style.display = 'none';
        }
    }
    
    panelShow =()=> {
        this.setState({isClicked:true});
    }
    panelHide =()=>{
        this.setState({isClicked:false});
    }

    componentDidMount () {
        
        callGetTemplateService().then((res)=>{
            if (res){
                this.setState({temData: res, isLoading: false});
            }
        });
        
        callGetKeysService().then((res)=>{
            this.setState({keys: res})
        });
        
    }
    selectKeys = () => {
        const templateName = document.querySelector("#select-template").value;
        callGetKeysService().then((res)=>{
            const arrayKeys = [];
            res.map(getkeys=>{
                if (templateName === getkeys.doc_category){
                    getkeys.key_names.map(getThisKeys=>{
                        arrayKeys.push(getThisKeys)
                    })
                }
            })
            this.setState({keyNames: arrayKeys})
        });
    }

    
  render() {
    const { isLoading, temData, isClicked, pdfUrl, pageCategory } = this.state;
    
    return (
        <div>
        <div className="d-sm-flex align-items-center justify-content-between mb-4">
            <h1 className="h3 mb-0 text-gray-800">Maintain Templates</h1>
        </div>
        <div className="pdf-reader">
            <div className="content shadow">
                {(pageCategory !== '') ? (
                    <div className="text-right">
                        {!isClicked ? 
                            (<a className="btn btn-link" onClick={this.panelShow}>
                                <FontAwesomeIcon className="mr-2" icon={faAngleDoubleRight} size='1x'/>
                            </a>):
                            (<a className="btn btn-link" onClick={this.panelHide}>
                                <FontAwesomeIcon className="mr-2" icon={faAngleDoubleLeft} size='1x'/>
                            </a>)
                        }
                            
                            
                        </div>
                ) : ''}
                
                <div className="row">
                
                    <div className={!isClicked?"col-md-9 canvas-min":"col-md-12 canvas-max"}>
                        <CanvasViewer dataFromParent={this.state} />
                    </div>
                    <div id="options-panel" className={!isClicked?"right-panel col-md-3":"right-panel col-md-3 d-none"} >
                        <div className="tabs">
                            {!isLoading ? (
                                <div className="tab-content" id="myTabContent">
                                <div className="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
                                    <div className="form-group mt-4">
                                        <label className="" htmlFor="validationCustom03">Select Doc. Template </label>
                                        <select className="browser-default custom-select" onChange={this.onClickHandler} defaultValue={'default'} id="select-template">
                                            <option value="default" hidden="hidden">Choose here</option>
                                            {temData.map(template => (
                                                <option key={template.page_name} data-url={template.page_filename} data-name={template.page_name} value={template.page_category}>{template.page_category}</option>
                                            ))}
                                        </select>
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="category1">Doc Category</label>
                                        <input disabled 
                                                type="email" 
                                                className="form-control" 
                                                id="doc-category" 
                                                aria-describedby="templateOption1" 
                                                defaultValue={this.state.pageCategory} />
                                        
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="category2">Doc. Keyword</label>
                                        <textarea className="form-control" 
                                                id="doc-keyword" 
                                                rows="2" 
                                                defaultValue={this.state.titleKeyWord}
                                                onChange={()=>{this.handleChangeEvent(this.state.titleKeyWord);}}  />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="category3">Select Key</label>
                                        <select className="browser-default custom-select" defaultValue={'default'} id="select-key" onClick={this.selectKeys}>
                                            {this.state.keyNames.map((keyname) => (
                                                <option key={keyname} value={keyname}>{keyname}</option>
                                            )
                                            )}
                                        </select>
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="category4">Key Keyword</label>
                                        <input type="email" 
                                            className="form-control" 
                                            id="key-keyword" 
                                            aria-describedby="templateOption4" 
                                            defaultValue={this.state.keyKeyWord}
                                            onChange={()=>{this.handleChangeEvent(this.state.keyKeyWord);}} />
                                    </div>
                                    <div className="form-group">
                                        <label htmlFor="category5">Value Position</label>
                                        <input disabled type="email" 
                                            className="form-control" 
                                            id="value-position" 
                                            aria-describedby="templateOption5" 
                                            defaultValue={this.state.valueKeyWord} />
                                    </div>
                                    <div className="form-group">
                                        <div className="form-check">
                                        <input className="form-check-input" type="checkbox" id="advance-config" onChange={this.handleAdvanceConfig}/>
                                        <label className="form-check-label" htmlFor="advance-config">
                                            Advance Configuration
                                        </label>
                                        </div>
                                    </div>
                                    <div className="config-wrap">
                                        <div className="form-group">
                                            <div className="form-check">
                                            <input className="form-check-input" type="checkbox" id="multuple-keys" onChange={this.changeMultipleKeyStatus}/>
                                            <label className="form-check-label" htmlFor="multuple-keys">
                                                Multiple Keys
                                            </label>
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <div className="form-check">
                                            <input className="form-check-input" type="checkbox" id="multuple-values" />
                                            <label className="form-check-label" htmlFor="multuple-values">
                                                Multiple Values
                                            </label>
                                            </div>
                                        </div>
                                        <div className="form-group">
                                            <div className="form-check">
                                            <input className="form-check-input" type="checkbox" id="key-value-fix" />
                                            <label className="form-check-label" htmlFor="key-value-fix">
                                                Key-Value Fix distance
                                            </label>
                                            </div>
                                        </div>
                                        <div className="form-group mt-4">
                                            <label className="" htmlFor="value-type">Value Type </label>
                                            <select className="browser-default custom-select" id="value-type" >
                                                <option value="">Choose here</option>
                                                <option value="String">String</option>
                                                <option value="Numeric">Numeric</option>
                                                <option value="AlphaNumeric">AlphaNumeric</option>
                                                <option value="Date">Date</option>
                                                <option value="Entity">Entity</option>
                                                <option value="Tick Mark">Tick Mark</option>
                                                <option value="Stamp">Stamp</option>
                                            </select>
                                        </div>
                                        <div className="form-group mt-4">
                                            <label className="" htmlFor="value-cut">Value Cut </label>
                                            <select className="browser-default custom-select" id="value-cut" defaultValue="">
                                                <option value="">Choose here</option>
                                                <option value="Right">Right</option>
                                                <option value="Left">Left</option>
                                                <option value="None">None</option>
                                            </select>
                                        </div>
                                        <div className="form-group mt-4">
                                            <label className="" htmlFor="value-cut-length">Value Cut Length </label>
                                            <input type="text" className="form-control" aria-label="value-cut-length" aria-describedby="value-cut-length" defaultValue="0" id="value-cut-length"/>
                                        </div>
                                        <div className="form-group mt-4">
                                            <label className="" htmlFor="value-parser">Value Parser: </label>
                                            <input type="text" className="form-control" aria-label="value-parser" aria-describedby="value-parser" defaultValue="<value>" id="value-parser" />
                                        </div>
                                    </div>

                                    <div className="form-group row mt-5">
                                        <div className="col-sm-10">
                                            <button type="button" className="btn btn-primary waves-effect mr-3 pr-3 pl-3" id="save-template" disabled>Save</button>
                                            <button type="button" className="btn btn-outline-primary no-bg-hover" id="discard-changes" disabled>Discard</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            ) : (<p className="ml-3 mt-3">Loading...</p>)}
                            
                        </div>
                    </div>
                </div>
            </div>
        </div>
        </div>
    )
    
  }
}
export default PdfReader;