import React, { Component } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { 
  faSearch,
  faBell,
  faEnvelope,
  faBars
} from '@fortawesome/free-solid-svg-icons'
import profile from "../../assets/undraw_profile_2.svg";
import profile_3 from "../../assets/undraw_profile_3.svg";
import $ from 'jquery';


class Header extends React.Component {
    toggleSideBar = () => {
        $("body").toggleClass("sidebar-toggled");
        $(".sidebar").toggleClass("toggled");
        if ($(".sidebar").hasClass("toggled")) {
          $('.sidebar .collapse').collapse('hide');
        };
      }
    render () {
        return (
            <nav className="navbar navbar-expand navbar-light bg-white topbar mb-4 static-top shadow">
                
                <div className="text-center d-none d-md-inline">
                  <button id="sidebarToggleTop" className="btn btn-link rounded-circle mr-3" onClick={this.toggleSideBar}>
                      <FontAwesomeIcon icon={faBars} size='1x'/>
                  </button>
                </div>
                <div><span>OASIS (OCR Automated Document Analysis)</span></div>
                
                

                <ul className="navbar-nav ml-auto">
                  <li className="nav-item dropdown no-arrow d-sm-none">
                      <a className="nav-link dropdown-toggle" href="#" id="searchDropdown" role="button"
                          data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                          <FontAwesomeIcon icon={faSearch} size='1x'/>
                      </a>
                      <div className="dropdown-menu dropdown-menu-right p-3 shadow animated--grow-in"
                          aria-labelledby="searchDropdown">
                          <form className="form-inline mr-auto w-100 navbar-search">
                              <div className="input-group">
                                  <input type="text" className="form-control bg-light border-0 small"
                                      placeholder="Search for..." aria-label="Search"
                                      aria-describedby="basic-addon2" />
                                  <div className="input-group-append">
                                      <button className="btn btn-primary" type="button">
                                          <FontAwesomeIcon icon={faSearch} size='1x'/>
                                      </button>
                                  </div>
                              </div>
                          </form>
                      </div>
                  </li>

                  <li className="nav-item dropdown no-arrow mx-1">
                      <a className="nav-link dropdown-toggle" href="#" id="alertsDropdown" role="button"
                          data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                          <FontAwesomeIcon icon={faBell} size='1x'/>
                          <span className="badge badge-danger badge-counter">3+</span>
                      </a>
                      <div className="dropdown-list dropdown-menu dropdown-menu-right shadow animated--grow-in"
                          aria-labelledby="alertsDropdown">
                          <h6 className="dropdown-header">
                              Alerts
                          </h6>
                          <a className="dropdown-item d-flex align-items-center" href="#">
                              <div className="mr-3">
                                  <div className="icon-circle bg-primary">
                                      <i className="fas fa-file-alt text-white"></i>
                                  </div>
                              </div>
                              <div>
                                  <div className="small text-gray-500">Nov 23, 2020</div>
                                  <span className="font-weight-bold">Unread Notification</span>
                              </div>
                          </a>
                          <a className="dropdown-item text-center small text-gray-500" href="#">Show All Alerts</a>
                      </div>
                  </li>
                  <li className="nav-item dropdown no-arrow mx-1">
                      <a className="nav-link dropdown-toggle" href="#" id="messagesDropdown" role="button"
                          data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                          <i className="fas fa-envelope fa-fw"></i>
                          <FontAwesomeIcon icon={faEnvelope} size='1x'/>
                          <span className="badge badge-danger badge-counter">7</span>
                      </a>
                      <div className="dropdown-list dropdown-menu dropdown-menu-right shadow animated--grow-in"
                          aria-labelledby="messagesDropdown">
                          <h6 className="dropdown-header">
                              Messages
                          </h6>
                          <a className="dropdown-item d-flex align-items-center" href="#">
                              <div className="dropdown-list-image mr-3">
                                  <img className="rounded-circle" src={profile_3}
                                      alt="" />
                                  <div className="status-indicator bg-success"></div>
                              </div>
                              <div className="font-weight-bold">
                                  <div className="text-truncate">Hi there! Test for unread message</div>
                                  <div className="small text-gray-500">Jane Doe · 58m</div>
                              </div>
                          </a>
                          <a className="dropdown-item d-flex align-items-center" href="#">
                              <div className="dropdown-list-image mr-3">
                                  <img className="rounded-circle" src="https://source.unsplash.com/Mv9hjnEUHR4/60x60"
                                      alt="" />
                                  <div className="status-indicator bg-success"></div>
                              </div>
                              <div>
                                  <div className="text-truncate">Hello! Test for read message.</div>
                                  <div className="small text-gray-500">Jane Doe · 2w</div>
                              </div>
                          </a>
                          <a className="dropdown-item text-center small text-gray-500" href="#">Read More Messages</a>
                      </div>
                  </li>

                  <div className="topbar-divider d-none d-sm-block"></div>
                  <li className="nav-item dropdown no-arrow">
                      <a className="nav-link dropdown-toggle" href="#" id="userDropdown" role="button"
                          data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                          <span className="mr-2 d-none d-lg-inline text-gray-600 small">John Doe</span>
                          <img className="img-profile rounded-circle"
                          src={profile}/>
                      </a>
                      <div className="dropdown-menu shadow animated--grow-in"
                          aria-labelledby="userDropdown">
                          <a className="dropdown-item" href="#">
                              <i className="fas fa-user fa-sm fa-fw mr-2 text-gray-400"></i>
                              Profile
                          </a>
                          <a className="dropdown-item" href="#">
                              <i className="fas fa-cogs fa-sm fa-fw mr-2 text-gray-400"></i>
                              Settings
                          </a>
                          <a className="dropdown-item" href="#">
                              <i className="fas fa-list fa-sm fa-fw mr-2 text-gray-400"></i>
                              Activity Log
                          </a>
                          <div className="dropdown-divider"></div>
                          <a className="dropdown-item" href="#" data-toggle="modal" data-target="#logoutModal">
                              <i className="fas fa-sign-out-alt fa-sm fa-fw mr-2 text-gray-400"></i>
                              Logout
                          </a>
                      </div>
                  </li>
              </ul>
            
               


            </nav>
           
        )
    }
}
export default Header;