const axios = require('axios');

export const callUploadService = (bodyData) => {
  const requestOptions = {
      method: 'POST',
      encType: 'multipart/form-data',
      files: JSON.stringify(bodyData)
  };
  //fetch('/upload', requestOptions)  //comment out if wanna edit in localhost
  fetch(process.env.REACT_APP_SERVER_URL+'/upload', requestOptions)
  .then((response) => response.json())
  .then((data) => {
    console.log(data);
  });
}

export const callGetTemplateService = () => {
    return axios.get(process.env.REACT_APP_SERVER_URL+'/templates')
    //return axios.get('/templates') //comment out if wanna edit in localhost
    .then(response => {
     return response.data.templates;

    }, error => {
      console.log(error);
    });
}

export const callUpdateTemplateService = (bodayData) => {
  const requestOptions = {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(bodayData)
  };
  return fetch(process.env.REACT_APP_SERVER_URL+'/templates', requestOptions)
  //return fetch('/templates', requestOptions) //comment out if wanna edit in localhost
    .then((response) => response.json())
    .then((data) => {
      return data;
    });
  
}

export const callDeleteTemplateService = (bodayData) => {
  const requestOptions = {
      method: 'DELETE',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(bodayData)
  };
  fetch(process.env.REACT_APP_SERVER_URL+'/templates', requestOptions)
  //fetch('/templates', requestOptions) //comment out if wanna edit in localhost
  .then((response) => response.json())
  .then((data) => {
    console.log(data);
  });
}

export const callGetKeysService = () => {
    return axios.get(process.env.REACT_APP_SERVER_URL+'/keys')
    //return axios.get('/keys') //comment out if wanna edit in localhost
    .then(response => {
      return response.data.keys
    }, error => {
      console.log(error);
    });
}

export const callPostKeysService = (bodayData) => {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(bodayData)
    };
    fetch(process.env.REACT_APP_SERVER_URL+'/keys', requestOptions)
    //fetch('/keys', requestOptions) //comment out if wanna edit in localhost
    .then((response) => response.json())
    .then((data) => {
      console.log(data);
    });
}

// getting the json file in every template file to determine word or line
export const callGetTemplateFileService = (bodayData) => {
  const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(bodayData)
  };
  return fetch(process.env.REACT_APP_SERVER_URL+'/file', requestOptions)
  //return fetch('/file', requestOptions) //comment out if wanna edit in localhost
    .then((response) => response.json())
    .then((data) => {
      return data;
    });
}

// getting the json file in every template file to determine word or line
export const callGetPDFFileService = (bodayData) => {
  const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(bodayData)
  };
  return fetch(process.env.REACT_APP_SERVER_URL+'/pdfview', requestOptions)
  //return fetch('/pdfview', requestOptions) //comment out if wanna edit in localhost
  .then((response) => {return response.json()})
  .catch(error => {
    console.log(error);
  });
}