import Konva from 'konva';
import $ from 'jquery';
import {
  callGetTemplateFileService,
  callPostKeysService, 
  callGetKeysService,
  callDeleteTemplateService
} from "../../services/service";
import {saveTemplate} from './saveTemplate'

export const renderBorder = (data, pageName) => {
  
    let jsonFileName = "";
    document.querySelector("#save-template").disabled = false;
    document.querySelector("#discard-changes").disabled = false;
    let arrayBlocks = [];
    let getTemplateIndex = '';


    const createBorderLayer = new Konva.Layer();
    let keyCreated = false;
    const canvasWidth = 730;
    const canvasHeight = 1030;
    const stage = new Konva.Stage({
        container: 'container',
        width: canvasWidth,
        height: canvasHeight
    });

    stage.add(createBorderLayer);



    // COUNTER FOR INDEXING

    var counter = (function() {
      var privateCounter = 0;
      function changeBy(val) {
        privateCounter += val;
      }
    
      return {
        increment: function() {
          changeBy(1);
        },
        value: function() {
          return privateCounter;
        }
      };
    })();




    // RENDERING BORDER BOX

    function renderBorderFromData () {
      
      const templateCategory = document.querySelector("#select-template").value;
      var selectedIndex = document.querySelector("#select-template").options.selectedIndex;
      const templatePageName = document.querySelector("#select-template").options[selectedIndex].getAttribute('data-name');
      for (let [x,y] of Object.entries(data)) {
        
        for (let [i,v] of Object.entries(y)){
          
          if (v.page_name === templatePageName && v.page_category === templateCategory) {
            getTemplateIndex = i;
          }
          
          if (pageName === v.page_name){
            const forms = v.page_templates[0].forms;
            let bboxPolygon = {};
            
            bboxPolygon.bounding_box = v.page_templates[0].bounding_box;
            bboxPolygon.polygon = v.page_templates[0].polygon;
            
            if (v.page_templates[0].bounding_box !== undefined){
              
              createBoundingBox (
                v.page_templates[0].bounding_box.Width,
                v.page_templates[0].bounding_box.Height,
                v.page_templates[0].bounding_box.Left,
                v.page_templates[0].bounding_box.Top,
                "blue",
                false,
                "title",
                "doctitle_"+i,
                v.page_templates[0].words[0],
                "",
                bboxPolygon,
                null,
                v.page_templates[0].word_index,
                null,
                '0');
              
              for (let [a,b] of Object.entries(forms)){
                

                let keybboxPolygon = {};
                let valuebboxPolygon = {};
                let configs = {};
  
                keybboxPolygon.bounding_box = b.key_bbox;
                keybboxPolygon.polygon = b.key_polygon;
                
                valuebboxPolygon.bounding_box = b.value_bbox;
                valuebboxPolygon.polygon = b.value_polygon;
  
                configs.key_multi = b.key_multi;
                configs.value_multi = b.value_multi;
                configs.value_type = b.value_type;
                configs.value_fix_distance = b.value_fix_distance ;
                configs.value_cut = b.value_cut;
                configs.value_cut_chars = b.value_cut_chars;
                configs.value_parser = b.value_parser;
                
                if (b.key_bbox !== undefined){
                  createBoundingBox (
                    b.key_bbox.Width,
                    b.key_bbox.Height,
                    b.key_bbox.Left,
                    b.key_bbox.Top,
                    "red",
                    false,
                    "key",
                    "dockeyval_"+a,
                    b.key_name,
                    "",
                    keybboxPolygon,
                    configs,
                    b.key_index,
                    null,
                    '0');
                    
                    
                    let i = 0;
                    while (i <= b.value_polygon_length) {
                      
                      
                      if (b["value_bbox"] !== undefined || (b["value"+((i===0)?'':i)+"_bbox"] !== undefined)){
                        createBoundingBox (
                          b["value"+((i==0)?'':i)+"_bbox"].Width,
                          b["value"+((i==0)?'':i)+"_bbox"].Height,
                          b["value"+((i==0)?'':i)+"_bbox"].Left,
                          b["value"+((i==0)?'':i)+"_bbox"].Top,
                          "lime",
                          false,
                          "value",
                          "dockeyval_"+a,
                          b.key_name,
                          b.value_pos,
                          valuebboxPolygon,
                          configs,
                          b.key_index,
                          null,
                          '0');
                       
                      }
                      
                      i++;
                    }
                    
                    
                    
                  
                
                }
              }  
            }
                          
          }
        }
      }

      
    }renderBorderFromData();
    

    // CREATING BORDER BOX
    
    function checkBoundingBoxes (
      posX, 
      posY, 
      borderColor, 
      isWord, 
      isCreatedKey, 
      chechIdn, 
      keyValueText, 
      boxCounter) {
      
      let getData = "";
      jsonFileName = pageName;
      let bodayData = { jsonURL: jsonFileName+".json" };
      const posXRoundOff = (String(posX)).substring(0,5);
      const posXNum = Number(posXRoundOff);

      const posYRoundOff = (String(posY)).substring(0,5);
      const posYNum = Number(posYRoundOff);
      callGetTemplateFileService(bodayData)
      .then(data => {
          //createBoundingBox(data)
          if (jsonFileName ===  pageName) {
            getData = data;
            let loopStop = false;
            if (!loopStop){
              getData.Blocks.map((blocks, index)=>{
                
                if (isWord === "true") {

                    if (blocks.BlockType === "WORD") {
                              
                        blocks.Geometry.Polygon.map((polygon, indx) => {
                          
                            const polXRoundOff = (String(polygon.X)).substring(0,5);
                            const polXNum = Number(polXRoundOff);
                            const polYRoundOff = (String(polygon.Y)).substring(0,5);
                            const polYNum = Number(polYRoundOff);
                            if ((posXNum >= polXNum) && (posYNum >= polYNum)) {
                                arrayBlocks.push(blocks);
                                arrayBlocks.push(index);
                               
                            }
                        })
                    }
                }else{
                  if (blocks.BlockType === "LINE") {
                    
                    blocks.Geometry.Polygon.map((polygon, indx) => {
                        const polXRoundOff = (String(polygon.X)).substring(0,5);
                        const polXNum = Number(polXRoundOff);
                        const polYRoundOff = (String(polygon.Y)).substring(0,5);
                        const polYNum = Number(polYRoundOff);    
                    
                        if ((posXNum >= polXNum) && (posYNum >= polYNum)) {
                            arrayBlocks.push(blocks);
                            arrayBlocks.push(index);
                        }
                    })
                  }
                }
                
              });
            }
          }

          if (arrayBlocks.length > 0) {
            const getDataBox = arrayBlocks[arrayBlocks.length-2];
            const getDataIndex = arrayBlocks[arrayBlocks.length-1];
            const getBoundingBoxWidth = getDataBox.Geometry.BoundingBox.Width;
            const getBoundingBoxHeight = getDataBox.Geometry.BoundingBox.Height;
            const getBoundingBoxLeft = getDataBox.Geometry.BoundingBox.Left;
            const getBoundingBoxTop = getDataBox.Geometry.BoundingBox.Top;
           
            let getText = [];
            let valPosition = "";
            const getAllShapes = stage.find("Rect");
            let colorID = "";
            let idntyStr = "";
            let configs = {};

            
            let getBboxPolygons = {};
            getBboxPolygons.bounding_box = getDataBox.Geometry.BoundingBox
            getBboxPolygons.polygon = getDataBox.Geometry.Polygon
            
            const valueType = document.querySelector("#value-type").value;
            configs.key_multi = document.querySelector("#multuple-keys").checked;
            configs.value_multi = document.querySelector("#multuple-values").checked;
            configs.value_fix_distance  = document.querySelector("#key-value-fix").checked;
            configs.value_type = (valueType)?valueType:"String";
            configs.value_cut = document.querySelector("#value-cut").value;
            configs.value_cut_chars = document.querySelector("#value-cut-length").value;
            configs.value_parser = document.querySelector("#value-parser").value;
            let titleIndex = '';

            if (borderColor === "blue"){
              colorID="title";
              idntyStr=(chechIdn !== '') ? chechIdn : "doctitle_"+arrayBlocks.length + 1;
              titleIndex = getDataIndex;
            }
            
            if (borderColor === "red"){
              
              colorID="key";
              idntyStr=(chechIdn !== '') ? chechIdn : "dockeyval_"+arrayBlocks.length;
              //indxCopy=arrayBlocks.length
              localStorage.setItem('index', arrayBlocks.length);
            }
            if (borderColor === "lime"){
              
              colorID="value";
              idntyStr=(chechIdn !== '') ? chechIdn : "dockeyval_"+localStorage.getItem('index');
              localStorage.removeItem('index')
              
              
              getAllShapes.map(shapes=> {

                if (shapes.attrs.stroke === "red"){
                  if (shapes.attrs.active){
                    getText.push(shapes.attrs.text);
                  }
                  if (shapes.attrs.identifier === idntyStr){
                    const keyX = shapes.attrs.x;
                    const keyY = shapes.attrs.y;

                    if ((keyX / canvasWidth) < (getBoundingBoxLeft + getBoundingBoxWidth) && (keyY / canvasHeight) < (getBoundingBoxTop + getBoundingBoxHeight) &&
                    (keyY / canvasHeight) > getBoundingBoxTop) {
                      valPosition = "Right";
                    }
                    if ((keyX / canvasWidth) > (getBoundingBoxLeft + getBoundingBoxWidth) && (keyY / canvasHeight) < (getBoundingBoxTop + getBoundingBoxHeight) &&
                    (keyY / canvasHeight) < getBoundingBoxTop) {
                      valPosition = "Left";
                    }

                    if ((keyY / canvasHeight ) > (getBoundingBoxTop + getBoundingBoxHeight)) {
                      valPosition = "Above";
                    }

                    if ((keyY / canvasHeight ) < (getBoundingBoxTop + getBoundingBoxHeight) && (keyY / canvasHeight) < (getBoundingBoxTop + getBoundingBoxHeight) && 
                    (keyY / canvasHeight) < getBoundingBoxTop) {
                      valPosition = "Below";
                    }
                    
                  }
                }
              })
            }
            
            let keyNameInput = '';
            if (keyValueText !== null) {
              keyNameInput = keyValueText;
            }else{
              keyNameInput = getText[0];
            }
            
            createBoundingBox (
              getBoundingBoxWidth,
              getBoundingBoxHeight,
              getBoundingBoxLeft,
              getBoundingBoxTop,
              borderColor,
              isCreatedKey,
              colorID,
              idntyStr,
              keyNameInput,
              valPosition,
              getBboxPolygons,
              configs,
              getDataIndex,
              titleIndex,
              boxCounter)
          }
      });  
    }


    // BORDER BOX

    function createBoundingBox (
      width, 
      height, 
      x, 
      y, 
      color, 
      isCreatedKey, 
      id, 
      idn, 
      text, 
      valPosition, 
      boxData, 
      configs, 
      index, 
      titleIndex,
      boxCounter) {
        counter.increment();
        const selectedIdx = document.querySelector("#select-template").options.selectedIndex;
        const tempName = document.querySelector("#select-template").options[selectedIdx].getAttribute('data-name');
        
        var newRect = new Konva.Rect({
          x: x  * canvasWidth,
          y: y  * canvasHeight,
          width: width * canvasWidth,
          height: height * canvasHeight,
          fill: 'transparent',
          stroke: color,
          strokeWidth: 2,
          active: false,
          id: id+'_'+counter.value(),
          identifier: idn,
          text: text,
          valpos: (valPosition!=="")?valPosition:"none",
          boxData: boxData,
          name: tempName,
          configs: configs,
          key_index : index,
          title_index: titleIndex,
          count_box: boxCounter
        });

        
        createBorderLayer.add(newRect);
        stage.draw();
        keyCreated = isCreatedKey;
        clickRectEvent(newRect, idn, color);
        callConfigEvents();
        arrayBlocks = [];
        $("#myModal").data('bs.modal', null); 
        $("[data-dismiss=modal]").trigger({ type: "click" });

    }
    

    // MOUSE POSITION
    
    function getMousePos(canvas, evt) {
      var rect = canvas.getBoundingClientRect();
      return {
        x: evt.clientX - rect.left,
        y: evt.clientY - rect.top
      };
    }

    
    // CALL MODAL  

    function callOptions () {
      $(".modal-body .list-group").show();
      $(".add-details").addClass("d-none");
      $("#myModal")
      .modal('show')
      .on('shown.bs.modal', function () {
          $(".modal-body .list-group").show();
          $(".add-details").addClass("d-none");
          $("#detail-name").val("");
      });
    }
    function callModalCreatorValue() {
      $(".modal-body .list-group").show();
      $("#myModalVal")
      .modal('show')
    }



    // E V E N T S //
    
    // CLICK EVENT SHAPES ON THE CANVAS
    function clickRectEvent(newRect, idn, color){
    
      newRect.on("click", function(e){ 
        e.evt.preventDefault()
        
        const templateCategory = document.querySelector("#select-template").value;
        const getStage = stage.find('Rect');
        if (this.stroke() === "red") {
          const getThisIdn = this.attrs.identifier 
          let flag = false;
          let flagPos = false;
          getStage.map(rect=>{
            
            rect.attrs.strokeWidth = 2;
            rect.attrs.active = false;
            if (getThisIdn === rect.attrs.identifier) {
              //console.log("rect:", rect.attrs)  
              rect.attrs.strokeWidth = 4;

              //CONFIGS
              document.querySelector("#multuple-keys").checked = rect.attrs.configs.key_multi;
              document.querySelector("#multuple-values").checked = rect.attrs.configs.value_multi;
              document.querySelector("#key-value-fix").checked = rect.attrs.configs.value_fix_distance;
              document.querySelector("#value-type").value = rect.attrs.configs.value_type;
              document.querySelector("#value-cut").value = rect.attrs.configs.value_cut;
              document.querySelector("#value-cut-length").value = rect.attrs.configs.value_cut_chars;
              document.querySelector("#value-parser").value = rect.attrs.configs.value_parser;
              
              if (rect.attrs.stroke === "red"){
                $("#select-key").val(rect.attrs.text).trigger('change');
                document.querySelector("#key-keyword").value = rect.attrs.text;
              }
              if (!flagPos) {
                if (rect.attrs.stroke === "lime") {
                  document.querySelector("#value-position").value = rect.attrs.valpos;
                  flagPos=true;
                }
              }
            }
            createBorderLayer.draw();
          });
          this.attrs.active = true;
          createBorderLayer.draw();
        }
      })
    }

    
    let xPos = '';
    let yPos = '';
    stage.on('contextmenu', function (e) {
        e.evt.preventDefault(); 
        xPos = getMousePos(document.querySelector("canvas"), e.evt).x / canvasWidth;
        yPos = getMousePos(document.querySelector("canvas"), e.evt).y / canvasHeight;

        if (e.target.className === 'Rect') {
          const shapeIdentfr= e.target.attrs.identifier;
          $("#deleteShape").attr('data-identifier', shapeIdentfr)
          $("#deleteShape").modal('show')
            
        }else{
          if  (!keyCreated) {
            callOptions();
          }else{
            callModalCreatorValue();
          } 
        }


        
    });
    
    
    document.querySelector("#save-details").addEventListener("click", function(e) {
      e.preventDefault();
      const getIsWord = $("#myModal .modal-content").attr("data-isword");
      const inputKeyBox = document.querySelector("#myInput").value;
      let flag = false;
      const getCategory = document.querySelector("#myDropdown").getAttribute('data-keycategory');

      const bodayData = {
          doc_category: getCategory,
          key_names: inputKeyBox
      };

      if ($.trim(inputKeyBox).length > 0) {
        $("#myInput").removeClass("is-invalid");
        callGetKeysService().then((res)=>{
          res.map(keyParent=>{
            keyParent.key_names.map(keyNamesList => {
              
              if (!flag){
                
                callPostKeysService(bodayData);

                checkBoundingBoxes(xPos, yPos, "red", getIsWord, true, "", inputKeyBox, "1");
                //callPostKeysService(bodayData)
                $("[data-dismiss=modal]").trigger({ type: "click" });
                document.querySelector("#myInput").value = '';
                flag = true;
                //arrayBlocks = [];
              }
            })
          })
        });
      }else{
        $("#myInput").addClass("is-invalid");
      }
    });
    document.querySelector("#addValue").addEventListener("click", function(e) {
      e.preventDefault()
      checkBoundingBoxes(xPos, yPos, "lime", "false", false, "", null, '0');
      keyCreated = false;
      $("[data-dismiss=modal]").trigger({ type: "click" });
    });

    document.querySelector("#updateTitle").addEventListener("click", function(e) {
      e.preventDefault();
      const getAllRect = stage.find("Rect");
      getAllRect.map(rect=>{
        
        if (rect.attrs.stroke=== "blue"){
          //const getID = this.id;
          const rectId = rect.attrs.id;
          const getShapeId = stage.find("#"+rectId);
          
          getShapeId.destroy();
          checkBoundingBoxes(xPos, yPos, "blue", "false", false, "", null, '1');
          keyCreated = false;
          $("[data-dismiss=modal]").trigger({ type: "click" });
        }
      })     
      keyCreated = false;
      $("[data-dismiss=modal]").trigger({ type: "click" });
    });
    
    function updateKeyPosition (isWordFlag) {
      const getAllRect = stage.find("Rect");
      getAllRect.map(rect=>{
        if (rect.attrs.active=== true){
          
          const rectId = rect.attrs.id;
          const getShapeId = stage.find("#"+rectId);
          getShapeId.destroy();
          checkBoundingBoxes(xPos, yPos, "red", isWordFlag, false, rect.attrs.identifier, null, '1');
          keyCreated = false;
          $("[data-dismiss=modal]").trigger({ type: "click" });
        }
      })
    }
    
    
    function updateValuePosition(isWordFlag, identifier, update){
      const getAllRect = stage.find("Rect");
      let arrayRect = [];  
      let flag = false;
      let getArrayFirstId = [];
      let rectIdentifier = [];
      let boxCounter = '1';
      getAllRect.map(rect=>{
        if (rect.attrs.stroke === "lime") {
          if (rect.attrs.identifier === identifier){
            
            if (rect.attrs.count_box == 0){
              rectIdentifier.push(rect.attrs.identifier)
              arrayRect.push(rect)
            }
          }
         
        }
      })
      
      if (arrayRect.length > 0) {
        
        getArrayFirstId = arrayRect[0].attrs.id;
        //const getIdVal = rect.attrs.id;
        const getShapeId = stage.find("#"+getArrayFirstId);

        if (update) {
          boxCounter = '0';
          getShapeId.destroy();
        }
        if (!flag) {
          checkBoundingBoxes(xPos, yPos, "lime", isWordFlag, false, rectIdentifier[0], null,  boxCounter);
          flag = true;
        }
        keyCreated = false;
        $("[data-dismiss=modal]").trigger({ type: "click" });
      }
    }
    document.querySelector("#updateWordKeyPos").addEventListener("click", function(e) {
      e.preventDefault();
      updateKeyPosition("true");
    });
    document.querySelector("#updateLineKeyPos").addEventListener("click", function(e) {
      e.preventDefault();
      updateKeyPosition("false");
    });
    document.querySelector("#updateWordValuePos").addEventListener("click", function(e) {
      e.preventDefault();
      const findShapes = stage.find('Rect')
      let getLimeIdentfr = '';
      {findShapes.map(rect=>{
        if (rect.attrs.active === true) {
          getLimeIdentfr = rect.attrs.identifier 
        }
      })}
      updateValuePosition("true", getLimeIdentfr, true)
    });
    document.querySelector("#updateLineValuePos").addEventListener("click", function(e) {
      e.preventDefault();
      const findShapes = stage.find('Rect')
      let getLimeIdentfr = '';
      {findShapes.map(rect=>{
        if (rect.attrs.active === true) {
          getLimeIdentfr = rect.attrs.identifier 
        }
      })}
      updateValuePosition("false", getLimeIdentfr, true)
    });
    document.querySelector("#deleteTemplate").addEventListener("click", function(e) {
      e.preventDefault();
      $("[data-dismiss=modal]").trigger({ type: "click" });
      $('#myModal').on('hidden.bs.modal', function () {
        $("#deleteTemplateMod").modal('show')
      });
    });
    document.querySelector("#deleteTemplateBtn").addEventListener("click", function(e) {
      e.preventDefault();
      let bodayData = { templateIndex: getTemplateIndex };
      callDeleteTemplateService(bodayData);
      $("[data-dismiss=modal]").trigger({ type: "click" });
      window.location.reload(false)
    });
    
    

    document.querySelector("#addLineAsValuePos").addEventListener("click", function(e) {
      e.preventDefault();
      const findShapes = stage.find('Rect')
      let getLimeIdentfr = '';
      {findShapes.map(rect=>{ 
        if (rect.attrs.active === true) {
          getLimeIdentfr = rect.attrs.identifier 
        }
      })}
      updateValuePosition("false", getLimeIdentfr, false)
    });

    document.querySelector("#addWordAsValuePos").addEventListener("click", function(e) {
      e.preventDefault();
      const findShapes = stage.find('Rect')
      let getLimeIdentfr = '';
      {findShapes.map(rect=>{
        if (rect.attrs.active === true) {
          getLimeIdentfr = rect.attrs.identifier 
        }
      })}
      updateValuePosition("true", getLimeIdentfr, false)
    });

    document.querySelector("#delete-key").addEventListener("click", function(e) {
      e.preventDefault();

      const shapeIdentifier = document.querySelector("#deleteShape").getAttribute('data-identifier');
      const getAllShapes = stage.find("Rect");

      getAllShapes.map(shape=>{
        if(shape.attrs.identifier === shapeIdentifier){
          const getShapeId = shape.attrs.id;
          const findgetShapeById = stage.find("#"+getShapeId);
          findgetShapeById.destroy()
          stage.draw();
        }
      })
      $("[data-dismiss=modal]").trigger({ type: "click" });
      
    });

    // EVENTS
    document.querySelector("#select-key").addEventListener("change",  (e)=>{
      const getShapes = stage.find('Rect');
      {getShapes.map(rect=>{
        rect.attrs.strokeWidth = 2;
        rect.attrs.active = false;
        if (e.target.value === rect.attrs.text){
          if (rect.attrs.stroke === "red"){
            rect.attrs.active = true;  
          }
          rect.attrs.strokeWidth = 4;
          stage.draw();
        }
        stage.draw();
      })}
    });


    // ADVANCE CONFIG EVENTS
    function callConfigEvents() {
      
      function getShapesConfig(typ, evt, configType){
        const getShapes = stage.find('Rect');
        {getShapes.map(rect=>{
          if (rect.attrs.active) {
            
            if (configType === 'configCheckboxes'){
              if (typ === 'multi-key') {
                rect.attrs.configs.key_multi = evt.target.checked;
              }
              if (typ === 'multi-values') {
                rect.attrs.configs.value_multi = evt.target.checked;
              }
              if (typ === 'key-value-fix') {
                rect.attrs.configs.value_fix_distance = evt.target.checked;
              }
            }

            if (configType === 'configDropdown'){
              if (typ === 'value-type') {
                rect.attrs.configs.value_type = evt.target.value;
              }
              if (typ === 'value-cut') {
                rect.attrs.configs.value_cut = evt.target.value;
              }
              if (typ === 'value-cut-length') {
                rect.attrs.configs.value_cut_chars = evt.target.value;
              }
              if (typ === 'value-parser') {
                rect.attrs.configs.value_parser = evt.target.value;
              }
            }
            
          }
        })}
      }

      function configCheckboxes(type, event) {
        const getTypes = type;
        const getEvt = event;
        getShapesConfig(getTypes, getEvt, 'configCheckboxes');
      }
      function configDropdown(type, event){
        const getTypes = type;
        const getEvt = event;
        getShapesConfig(getTypes, getEvt, 'configDropdown');
      }
      document.querySelector("#multuple-keys").onchange = function (e) {
        configCheckboxes("multi-key", e)
      }
      document.querySelector("#multuple-values").onchange = function (e) {
        configCheckboxes("multi-values", e)
      }
      document.querySelector("#key-value-fix").onchange = function (e) {
        configCheckboxes("key-value-fix", e)
      }
      document.querySelector("#value-type").onchange = function (e) {
        configDropdown("value-type", e)
      }
      document.querySelector("#value-cut").onchange = function (e) {
        configDropdown("value-cut", e)
      }
      document.querySelector("#value-cut-length").onchange = function (e) {
        configDropdown("value-cut-length", e)
      }
      document.querySelector("#value-parser").onchange = function (e) {
        configDropdown("value-parser", e)
      }
    }

    // SAVE TEMPLATE
    saveTemplate(stage);
}