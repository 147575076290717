import React, { Component } from "react";
 
class Home extends Component {
  render() {
    return (
      <div>
      <div className="d-sm-flex align-items-center justify-content-between mb-4">
            <h1 className="h3 mb-0 text-gray-800">Dashboard</h1>
        </div>
        <div className="content shadow">
        <h3 className="mb-5">An AI Powered OCR Automated Document Processing and Analysis</h3>
        <p>OASIS is not just an OCR, it is mimicing Human learning experience on document processing and analysis,
            starting from reading a pdf or images document, learning various document
            type and different templates, and learn from custom entities such as vendor names, material codes, etc.
            
            In the brain of OASIS, it is equiped with powerful machine learning classification to identify every single page
            of documents, Natural Language Processing (NLP) to extract key information and entities extraction to match with years
            experience of human in document processing.

            Those capabilities you can train the machine by yourself and doesnt require further technical development.
        </p>
 
        <p>Try out the capabilities and get started by adding your own document templates.</p>
        </div>
      </div>
    );
  }
}
 
export default Home;