import React from 'react';
import ReactDOM from 'react-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { 
  faPlus,
  faPen,
  faTimes
} from '@fortawesome/free-solid-svg-icons'
import { Document, Page } from 'react-pdf';
import {callGetKeysService} from "../../services/service";
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import $ from 'jquery';


const options = {
  cMapUrl: 'cmaps/',
  cMapPacked: true,
};

class CanvasViewer extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
          value: "",
          forms: "",
          keys: [],
          searchText: null,
          numPages: null
      }
    }
    filterFunction() {
      var input, filter, a, i;
        input = document.getElementById("myInput");
        filter = input.value.toUpperCase();
        var div = document.getElementById("myDropdown");
        a = div.getElementsByTagName("a");
        for (i = 0; i < a.length; i++) {
          var txtValue = a[i].textContent || a[i].innerText;
          if (txtValue.toUpperCase().indexOf(filter) > -1) {
            a[i].style.display = "";
          } else {
            a[i].style.display = "none";
          }
        }
        $("#myInput").removeClass("is-invalid");
    }
    callServiceKey =(arrList, keyCategory)=>{
      callGetKeysService().then((res)=>{
        res.map(keyParent=>{
          if (keyCategory === keyParent.doc_category){
            keyParent.key_names.map(keyNamesList => {
              arrList.push(keyNamesList);
            })
          }
        })
        this.setState({keys: arrList})
      });
    }
    //ON CHANGE EVENT FOR KEY
    handleDropdownChange = (e) => {
      const key = e.target.getAttribute('data-keyname');
      document.getElementById("myInput").value = (key)
      $("#myInput").removeClass("is-invalid");
    }
    
    //ADD NEW KEY
    showKeyModal=()=> {
      const keyCategory = document.querySelector("#myDropdown").getAttribute('data-keycategory');
      const arrList = [];  
      this.callServiceKey(arrList, keyCategory)
      $(".add-details").removeClass("d-none");
      $(".modal-body .list-group").hide();
    }
    addWordKey = event => {
      event.preventDefault();
      this.showKeyModal();
      $("#myModal .modal-content").attr("data-isword", true);
    }
    addLineKey = event => {
      event.preventDefault();
      this.showKeyModal();
      $("#myModal .modal-content").attr("data-isword", false);
    }

    backEvent=event=>{
      event.preventDefault()
      $(".add-details").addClass("d-none");
      $(".modal-body .list-group").show();
    }
    
    onDocumentLoadSuccess = ({ numPages }) => {
      this.setState({ numPages });
    }
    
    render () {
        
        return (
            <div id="pdf-canvas">
              <div className="modal fade" id="myModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLongTitle" aria-hidden="true" data-backdrop="static" data-keyboard="false">
                <div className="modal-dialog" role="document">
                  <div className="modal-content" data-isword="">
                    <div className="modal-header bg-primary">
                      <h5 className="modal-title text-white">Select</h5>
                      <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
                    <div className="modal-body p-0">
                      <div className="list-group p-3 list-group-flush">
                        <button type="button" 
                          className="list-group-item list-group-item-action"
                          onClick={this.addWordKey}>
                          <FontAwesomeIcon className="mr-2" icon={faPlus} size='1x'/>Add <strong>WORD</strong> as new Key
                        </button>
                        <button type="button" 
                          className="list-group-item list-group-item-action"
                          onClick={this.addLineKey}>
                          <FontAwesomeIcon className="mr-2" icon={faPlus} size='1x'/>Add <strong>LINE</strong> as new Key
                        </button>
                        
                        <button type="button" 
                          className="list-group-item list-group-item-action"
                          id="updateWordKeyPos">
                          <FontAwesomeIcon className="mr-2" icon={faPen} size='1x'/>Update <strong>WORD</strong> as selected Key
                        </button>
                        <button type="button" 
                          className="list-group-item list-group-item-action"
                          id="updateLineKeyPos">
                          <FontAwesomeIcon className="mr-2" icon={faPen} size='1x'/>Update <strong>LINE</strong> as selected Key
                        </button>
                        <button type="button" 
                          className="list-group-item list-group-item-action"
                          id="updateWordValuePos">
                          <FontAwesomeIcon className="mr-2" icon={faPen} size='1x'/>Update <strong>WORD</strong> as Value Position
                        </button>
                        <button type="button" 
                          className="list-group-item list-group-item-action"
                          id="updateLineValuePos">
                          <FontAwesomeIcon className="mr-2" icon={faPen} size='1x'/>Update <strong>LINE</strong> as Value Position
                        </button>
                        <button type="button" 
                          className="list-group-item list-group-item-action"
                          id="updateTitle">
                          <FontAwesomeIcon className="mr-2" icon={faPen} size='1x'/>Update as Document Title
                        </button>
                        <button type="button" 
                          className="list-group-item list-group-item-action"
                          id="addLineAsValuePos">
                          <FontAwesomeIcon className="mr-2" icon={faPlus} size='1x'/>Add <strong>LINE</strong> as other Value position
                        </button>
                        <button type="button" 
                          className="list-group-item list-group-item-action"
                          id="addWordAsValuePos">
                          <FontAwesomeIcon className="mr-2" icon={faPlus} size='1x'/>Add <strong>WORD</strong> as other Value position
                        </button>
                        <button type="button" 
                          className="list-group-item list-group-item-action"
                          id="deleteTemplate">
                          <FontAwesomeIcon className="mr-2" icon={faTimes} size='1x'/>Delete Doc. Template
                        </button>
                      </div>
                      <div className="add-details pl-3 pr-3 d-none">
                        <form>
                          <div className="form-group row">
                            <label className="pl-3 mt-3">Please select or input key name</label>
                            <div className="col-sm-12">
                             
                              <div className="dropdown">
                                <div className="input-group mb-0">
                                  <input type="text" required id="myInput" className="form-control" placeholder="" aria-label="key names" aria-describedby="seatch-button" onKeyUp={this.filterFunction} />
                                  <div className="invalid-feedback">
                                    Please provide key name.
                                  </div>
                                </div>
                                
                                <div id="myDropdown" className="dropdown-content" data-keycategory={this.props.dataFromParent.pageCategory}>
                                 
                                    {this.state.keys.map(keyname => (
                                      <a key={keyname} data-keyname={keyname} onClick={this.handleDropdownChange}>{keyname}</a>
                                    ))}
                                    
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="form-group row">
                            <div className="col-sm-10">
                              <button type="button" className="btn btn-outline-primary no-bg-hover mr-2" id="back-details" onClick={this.backEvent}>Back</button>
                              <button type="button" className="btn btn-primary" id="save-details">OK</button>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal fade" id="myModalVal" tabIndex="-1" role="dialog" aria-labelledby="myModalVal" aria-hidden="true">
                <div className="modal-dialog" role="document">
                  <div className="modal-content">
                    <div className="modal-header bg-primary">
                      <h5 className="modal-title text-white">Set Value Position</h5>
                      <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
                    <div className="modal-body p-0 m-0">
                      <div className="list-group p-3 list-group-flush">
                        <button type="button" 
                          className="list-group-item list-group-item-action"
                          id="addValue" 
                          onClick={this.addValueBorder}>
                          Set Value Position
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal fade" id="deleteTemplateMod" tabIndex="-1" role="dialog" aria-labelledby="deleteTemplateMod" aria-hidden="true">
                <div className="modal-dialog" role="document">
                  <div className="modal-content">
                    <div className="modal-header bg-primary">
                      <h5 className="modal-title text-white">Delete Doc. Template</h5>
                      <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
                    <div className="modal-body">
                      <p>Are you sure you want to delete Doc. Template? Template will be deleted permamently.</p>
                    </div>
                    <div className="modal-footer form-group">
                      <button type="button" className="btn btn-primary" id="deleteTemplateBtn">Yes</button>
                      <button type="button" className="btn btn-outline-primary no-bg-hover" data-dismiss="modal">No</button>
                    </div>
                  </div>
                </div>
              </div>                  
              <div className="modal fade" id="deleteShape" tabIndex="-1" role="dialog" aria-labelledby="myModalVal" aria-hidden="true">
                <div className="modal-dialog" role="document">
                  <div className="modal-content">
                    <div className="modal-header bg-primary">
                      <h5 className="modal-title text-white">Delete Key</h5>
                      <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
                    <div className="modal-body p-0 m-0">
                      <div className="list-group p-3 list-group-flush">
                        <button type="button" 
                          className="list-group-item list-group-item-action"
                          id="delete-key">
                          <FontAwesomeIcon className="mr-2" icon={faTimes} size='1x'/>Delete Key
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal" tabIndex="-1" role="dialog" id="save-confirmation">
                <div className="modal-dialog" role="document">
                  <div className="modal-content">
                    <div className="modal-header bg-primary">
                      <h5 className="modal-title text-white">Save Confirmation</h5>
                      <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
                    <div className="modal-body mt-2 mb-2">Successfully Saved.</div>
                    <div className="modal-footer form-group mb-0">
                      <button type="button" className="btn btn-primary no-bg-hover" id="close-modal">Ok</button>
                    </div>
                  </div>
                </div>
              </div>
                
                
                
                <div id="react-pdf____container">
                <div id="container"></div>
                 {this.props.dataFromParent.pdfUrl ? 
                 
                  <div className="Example__container">
                    <div className="Example__container__document">
                      <Document
                        file={this.props.dataFromParent.pdfUrl}
                        onLoadSuccess={this.onDocumentLoadSuccess}
                        options={options}
                      >
                        <Page wrap={false} pageNumber={1} />
                      </Document>
                    </div>
                  </div>
                 : <p className="m-5">Select Doc. Template</p>}
                 </div>
                 {/*this.props.dataFromParent.pdfSrc ? 
                  <div>
                    <Document file={this.props.dataFromParent.pdfSrc} 
                    renderMode="canvas">
                      <Page wrap={true} pageNumber={1} 
                      customTextRenderer={this.makeTextRenderer(this.searchText)}/>
                    </Document>
                  </div>
                 : "Select Doc. Template"*/}
                 
                
            </div>
        )
    }
    documentLoad =()=> {
      return (<h1>test</h1>)
    }
}

export default CanvasViewer;