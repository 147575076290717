import React, { Component } from "react";
import {
    NavLink
  } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { 
  faHome, 
  faTasks, 
  faFilePdf, 
  faCheckSquare, 
  faThumbsUp, 
  faCog
} from '@fortawesome/free-solid-svg-icons'
import logo from "../../assets/logo.png"


class Sidebar extends React.Component {
    render () {
        return (
            <ul className="navbar-nav bg-gradient-primary sidebar sidebar-dark accordion" id="accordionSidebar">
              <a className="sidebar-brand d-flex align-items-center justify-content-center">
                  <div className="sidebar-brand-icon rotate-n-15">
                      <i className="fas fa-laugh-wink"></i>
                  </div>
                  <div className="sidebar-brand-text mx-3">OASIS</div>
              </a>
              
              <li className="nav-item">
                <NavLink to="/" className="nav-link">
                    <FontAwesomeIcon icon={faHome} size='1x'/>
                    <span>Home</span>
                </NavLink>
              </li>
              <div className="sidebar-heading mt-3 mb-3">
                Tasks
              </div>
              <li className="nav-item">
                  <NavLink to="/Todo" className="nav-link">
                    <FontAwesomeIcon icon={faTasks} size='1x'/>
                      <span>To Do</span>
                  </NavLink>
              </li>
              <div className="sidebar-heading mt-3 mb-3">
                Templates
              </div>
              <li className="nav-item">
                  <NavLink to="/Maintain" className="nav-link">
                    <FontAwesomeIcon icon={faFilePdf} size='1x'/>
                      <span>Maintain Templates</span>
                  </NavLink>
              </li>
              <li className="nav-item">
                  <NavLink to="/Test" className="nav-link">
                    <FontAwesomeIcon icon={faCheckSquare} size='1x'/>
                      <span>Test Templates</span>
                  </NavLink>
              </li>
              <li className="nav-item">
                  <NavLink to="/HumanReview" className="nav-link">
                    <FontAwesomeIcon icon={faThumbsUp} size='1x'/>
                      <span>Human Review</span>
                  </NavLink>
              </li>
              <div className="sidebar-heading mt-3 mb-3">
                Config
              </div>
              <li className="nav-item">
                  <NavLink to="/Configuration" className="nav-link">
                    <FontAwesomeIcon icon={faCog} size='1x'/>
                      <span>Configuration</span>
                  </NavLink>
              </li>
            </ul>
             
        )
    }
}
export default Sidebar;